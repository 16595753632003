@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --orchid-color: #613659;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
  --green: #0bda51;
  --dark: #222;
  --candy: #ff0800;
  --tea: #d0f0c0;
  --cherry: #fcc9b9;
  --grey1: #a09f9f;
  --grey2: #dee1e9;
  /* --white1: #fdfff5; */
  --white2: #ffffe4;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
