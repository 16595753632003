.headline {
  font-size: 3rem;
  font-family: Helvetica Neue;
  text-align: center;
  color: var(--orchid-color) !important;
  font-weight: 500;

  p {
    font-weight: 800;
    color: var(--candy) !important;
  }

  @media screen and (max-width: 450px) {
    white-space: nowrap;
    font-size: 2.1rem !important;
  }
}

.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 200px;
    border-radius: 15px;
    object-fit: cover;
  }

  .about-title {
    // color: var(--secondary-color);
    font-weight: 500;
    color: brown;
    font-size: 2rem;
  }

  .about-desc {
    font-size: 1rem;
    font-weight: 500;
    color: var(--dark);
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}
